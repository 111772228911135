<template>
  <section id="prospect">
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Customer Information">
          <b-row>
            <b-col md="5" class="border-right">
              <b-row>
                <b-col md="8" lg="6">
                  <div class="detail-info">
                    <span>Customer Name</span>
                    <h4>{{customerInformation.tp_client_name || '-'}} - {{ customerInformation.tp_client_type == 'residencial' ? 'Residential' : 'Company' }}</h4>
                  </div>
                  <div class="detail-info" v-if="customerInformation.tp_client_type == 'company'">
                    <span>Company Name</span>
                    <h4>{{customerInformation.tp_client_company || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Contact</span>
                    <h4 v-if="customerInformation.tp_client_email"><a :href="'mailto:' + customerInformation.tp_client_email" class="contact-info"><i class="ti-email"></i><span>{{ customerInformation.tp_client_email || '-'}}</span></a></h4>
                    <h4 v-if="customerInformation.tp_client_phone"><a :href="'https://web.whatsapp.com/send?phone='+customerInformation.tp_client_phone.replace('08', '628')" target="child" class="contact-info"><i class="fab fa-whatsapp"></i><span>{{ customerInformation.tp_client_phone || '-'}}</span></a></h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Address</span>
                    <h4>{{ customerInformation.tp_client_address || '-' }}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Source</span>
                    <h4>{{ $parent.prospectSrc(customerInformation.tp_source).label || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Contact Via</span>
                    <h4>{{ $parent.contactMedia(customerInformation.tp_contact_via).label || '-'}}</h4>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="5">
              <b-row>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Prospect Date</span>
                    <h4>{{ customerInformation.tp_date | moment('LLLL')}}</h4>
                  </div>
                </b-col>
                <b-col md=8>
                  <div class="detail-info">
                    <span>Purpose</span>
                    <h4>{{ customerInformation.tp_keperluan || '-' }}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>General Information</span>
                    <h4>{{customerInformation.tp_general_information || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8" v-if="customerInformation.tp_status_prospect == 'P'">
                  <div class="detail-info">
                    <span>Pending Reason</span>
                    <h4>{{customerInformation.tp_pending_reason || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8" v-else-if="customerInformation.tp_status_prospect == 'R'">
                  <div class="detail-info">
                    <span>Reject Reason</span>
                    <h4>{{customerInformation.tp_reject_reason || '-'}}</h4>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="detail-info">
                    <span>Status</span>
                    <div>
                      <b-badge :variant="$parent.prospectBadge[customerInformation.tp_status_prospect].color">
                        {{ $parent.prospectBadge[customerInformation.tp_status_prospect].word }}
                      </b-badge>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Follow Up History">
          <template v-if="$parent.lengthArr(followUpHistory)">
            <b-row>
              <b-col lg="8" md="10">
                <div class="timeline">
                  <div class="timeline-box" v-for="(value, key) in followUpHistory" :key="key">
                    <div class="timeline-head">
                      <h4 class="timeline__title">{{ value.tfu_date | moment('LL') }}</h4>
                      <b-badge v-if="value.tfu_status_done == 'done'" :variant="$parent.prospectBadge[value.tfu_status].color">
                        {{ $parent.prospectBadge[value.tfu_status].word }}
                      </b-badge>
                      <p v-else>Not Finished yet</p>
                      <span v-if="value.tfu_status_done == 'done'">Followed Up by: {{ value.tfu_person }}</span>
                    </div>
                    <div class="timeline-body">
                      <p>{{ value.tfu_result }}</p>
                      <p v-if="value.tfu_pending_reason">Pending Reason: {{ value.tfu_pending_reason }}</p>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <h3>Follow Up History is Empty.</h3>
          </template>
        </b-tab>
        <b-tab title="Survey History" v-if="$parent.lengthArr(surveyHistory)">
          <template v-if="$parent.lengthArr(surveyHistory)">
            <b-row>
              <b-col lg="6" md="8">
                <div class="detail-info">
                  <span>Survey Address</span>
                  <h4>{{ (surveyHistory[0] || {tss_address: ''}).tss_address }}</h4>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8" md="10">
                <div class="timeline">
                  <div class="timeline-box" v-for="(value, index) in surveyHistory" :key="index">

                    <div class="timeline-head">
                      <h4 class="timeline__title d-block">{{ value.tss_date | moment('LLL') }}</h4>                      
                      <template v-for="(v, k) in value.tss_image">
                        <b-button v-if="v" size="sm" variant="outline-info" @click="setLightBox(v)" :key="k" class="mt-2 mb-2" :class="k != 0 ? 'ml-2' : ''">                          
                          <i class="fas fa-image"></i>
                          See Photo
                        </b-button>

                        <b-button
                          class="ml-2 btn-icon"
                          variant="outline-warning"
                          v-b-tooltip.hover="'Edit Photo'"
                          v-if="v"
                          @click="showUpdateImage(v, value.tss_id, k)"
                          size="sm"
                          :key="'edit'+k"
                        ><i class="fas fa-pencil-alt"></i>
                        </b-button>
                      </template>
                      <p>Surveyor: {{ value.ms_name }}</p>
                    </div>
                    <div class="timeline-body">
                      <p>{{value.tss_result}}</p>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <h3>Survey History is Empty.</h3>
          </template>
        </b-tab>
        <!-- <b-tab title="Payment History"></b-tab> -->
      </b-tabs>
    </b-card>
    <div class="media-overlay" :class="showSurveyImg ? 'show' : ''" @click.stop="showSurveyImg = false">
      <div class="media-content" @click.stop>
        <div class="media-dialog parent-wrapper">
          <b-img :src="$parent.uploader(detailImg)" />
          <b-button size="sm" @click.stop="showSurveyImg = false" class="close-button btn" variant="danger"><i class="fas fa-times"></i></b-button>
        </div>
      </div>
    </div>

  <b-modal id="updateImage" title="Update Image" centered no-close-on-backdrop>
    <validation-observer v-slot="{ handleSubmit }" ref="VFormUpdateImage">
      <b-form @submit.prevent="handleSubmit(submitUpdateImage())">
        <b-form-row>
          <b-col md=12>
            <b-form-group label-for="monPhoto">
              <template #label>Photo</template>
              <Uploader v-model="dataModal.tss_image" :usePreview="true" type="survey_result"/>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <b-button size="md" variant="transparent" @click="closeModal('updateImage')">Cancel</b-button>
      <b-button size="md" variant="info" @click="submitUpdateImage()">Update</b-button>
    </template>
  </b-modal>

  </section>
</template>
<script>
import Gen from '@/libs/Gen'

  export default{
    props:{
      customerInformation: Object,
      followUpHistory: Array,
      surveyHistory: Array,
      detailImg: String
    },
    data() {
      return {
        showSurveyImg: false,
        dataModal: {}
      }
    },
    methods: {
      setLightBox(src){
        this.detailImg = src
        this.showSurveyImg = true
      },
      showUpdateImage(tss_image, tss_id, k){
        this.dataModal = {tss_image, tss_id, key: k}
        this.$bvModal.show('updateImage')
      },
      submitUpdateImage(){
        this.$refs.VFormUpdateImage.validate().then(success => {
          if(!success) return

          this.$swal({
            title: 'Update image...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })

          Gen.apiRest(
            '/do/' + this.$parent.modulePage,
            { data: {type: 'update-image-survey', ...this.dataModal} },
            'POST'
          ).then(res => {
            if(res){
              this.$swal({
                title: res.data.message,
                text: '',
                timer: 1500,
                icon: 'success'
              }).then(() => {
                this.$parent.apiGet()
                this.$bvModal.hide('updateImage')
              }).catch(() => {
                this.$parent.apiGet()
                this.$bvModal.hide('updateImage')
              })
            }
          })

        })
      },
      closeModal(id){
        this.$set(this, 'dataModal', {})
        this.$bvModal.hide(id)
      }
    },
    mounted(){
      setTimeout(() => {       
        if(!this.customerInformation) this.$parent.$router.push({ name: this.$parent.modulePage })      
       }, 800)
    }
  }
</script>
